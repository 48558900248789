'use strict';

/**
 * @ngInject
 */
function apiResidenceService(){
    var residenceId = null;
    this.setId = function (id) {
      residenceId = id;
      return residenceId;
    };
    this.getId = function(){
       return residenceId;
    };

}

module.exports = apiResidenceService;