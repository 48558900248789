
'use strict';

/**
 * @ngInject
 */
function getUIDFactory() {
  return function(prefix){
    do {
      prefix += Math.floor(Math.random() * 1000000);
    } while (document.getElementById(prefix));
    return prefix;
  };
}

module.exports = getUIDFactory;