'use strict';

/**
 * @ngInject
 */
function RepeatXTimesDirective(){
  // Runs during compile
  return {
    compile: function(tElement){
      var content = tElement.contents()[1].outerHTML;
      return function (scope, iElement, iAttrs) {


        scope.$watchCollection(iAttrs.repeatXTimes, function(newVal) {
          var inner = '';
          iElement.html(inner);
          for (var i = 0; i < newVal; i++) {
            inner +=  content ;
          }
          iElement.append(inner);
        });
      };
    }
  };
}

module.exports = RepeatXTimesDirective;