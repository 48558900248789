
'use strict';

/**
 * @ngInject
 */


// https://adresse.data.gouv.fr/api/
function ApiAdresseService($q, $http) {

  this.search = function getCurrentPosition(value) {
    var deferred = $q.defer();
    var API_URL = 'https://api-adresse.data.gouv.fr/search?type=' ;
    var filterType = 'city';

    if (!value) {
      deferred.resolve([]);
    } else {
      $http.get(API_URL + filterType + '&limit=5&q=' + value)
        .then(function (data) {
          //On retour le tableau d'adresse
          deferred.resolve(data.data.features);
        });
    }


    return deferred.promise;
  };
}
ApiAdresseService.$inject = ["$q", "$http"];

module.exports = ApiAdresseService;