'use strict';

var _ = require('lodash');



/**
 * @ngInject
 */
module.exports = function SupportController($scope) {

	/**
	 *	Finds an element by it's id in a collection.
	 */
	function findById(collection, id) {
		return _.find(collection, ['id', id]);
	}

	/**
	 *	Finds a theme by its name in a collection.
	 */
	function findThemeByName(collection, name) {
		return _.find(collection, ['name', name]);
	}

	/**
	 *	Finds a subject by "label" in a collection.
	 */
	function findSubjectByLabel(collection, label) {
		return _.find(collection, ['label', label]);
	}


	/**
	 *
	 */
	function residencesByLocation(residences) {
		return _(residences)
			.groupBy('localisation.id')
			.map(function(residences) {
				var location = _.first(residences).localisation;
				return _.assign({}, location, {
					residences: residences
				});
			})
			.value();
	}

	/**
	 *	Updates subjects depending on the current theme.
	 */
	function updateSubjects() {
		if ($scope.currentTheme != null && angular.isDefined($scope.currentTheme.subjects)) {
			$scope.subjects = $scope.currentTheme.subjects;
			$scope.currentSubject = _.first($scope.subjects);
		} else{
			$scope.subjects = [];
			$scope.currentSubject = {};
		}
	}

	/**
	 *	Updates residences depending on the current location.
	 */
	function updateResidences() {
		if ($scope.currentResidence != null && angular.isDefined($scope.currentLocation.residences)) {
			$scope.residences = $scope.currentLocation.residences;
			$scope.currentResidence = _.first($scope.residences);
		} else{
			$scope.residences = [];
			$scope.currentResidence = {};
		}
	}

	/**
	 *
	 */
	function shouldShowBookingBlock() {
		var code = _.get($scope, 'currentTheme.form.code');
		return (code === 'booking');
	}

	/**
	 *
	 */
	function shouldShowResidenceBlock() {
		var code = _.get($scope, 'currentTheme.form.code');

		return (
			(code === 'residence')
			|| (code === 'booking' && $scope.hasNoBookingNumber)
		);
	}

	/**
	 *
	 */
	function initDemandBlock(data) {
		// S'il y a une query string on préselectionne le thème Info résidence
		if (angular.isDefined(data.queryString.cityId)
				&& angular.isDefined(data.queryString.residenceId)) {
			$scope.themes = data.themes;
			$scope.currentTheme = findThemeByName($scope.themes, 'Info résidence');

			updateSubjects();

			$scope.currentSubject = findSubjectByLabel($scope.subjects, 'A propos de la résidence');
		} else {
			var currentThemeId = $('#thread_demand_theme').val();
			var currentSubjectId = $('#thread_demand_subject').val();

			$scope.themes = data.themes;

			// on remet les éléments présélectionnés au chargement de la page
			$scope.currentTheme = currentThemeId
				? findById($scope.themes, currentThemeId)
				: {};

			updateSubjects();

			$scope.currentSubject = currentSubjectId
				? findById($scope.subjects, currentSubjectId)
				: {};
		}
	}

	/**
	 *
	 */
	function initResidenceBlock(data) {
		// S'il y a une query string on préselectionne la ville et la résidence
		if (angular.isDefined(data.queryString.cityId)
				&& angular.isDefined(data.queryString.residenceId)) {
			$scope.locations = residencesByLocation(data.residences);
			$scope.currentLocation = findById($scope.locations, data.queryString.cityId);

			updateResidences();

			$scope.currentResidence = findById($scope.residences, data.queryString.residenceId);
		} else {
			var currentLocationId = $('#thread_residence_location').val();
			var currentResidenceId = $('#thread_residence_residence').val();

			$scope.locations = residencesByLocation(data.residences);

			// on remet les éléments présélectionnés au chargement de la page
			$scope.currentLocation = currentLocationId
				? findById($scope.locations, currentLocationId)
				: {};

			updateResidences();

			$scope.currentResidence = currentResidenceId
				? findById($scope.residences, currentResidenceId)
				: {};
		}
	}

	/**
	 *
	 */
	function init(data) {
		// on affiche le sélecteur de thèmes
		$('#js-support-theme').removeClass('hidden');
		$('#js-support-location').removeClass('hidden');

		$scope.hasNoBookingNumber = data.hasNoBookingNumber;

		initDemandBlock(data);
		initResidenceBlock(data);


	}

	/**
	 *
	 */
	$scope.themes = [];
	$scope.currentTheme = {};
	$scope.subjects = [];
	$scope.currentSubject = {};

	$scope.locations = [];
	$scope.currentLocation = {};
	$scope.residences = [];
	$scope.currentResidence = {};
	$scope.init = init;
	$scope.handleThemeChange = updateSubjects;
	$scope.handleLocationChange = updateResidences;
	$scope.shouldShowBookingBlock = shouldShowBookingBlock;
	$scope.shouldShowResidenceBlock = shouldShowResidenceBlock;
};
module.exports.$inject = ["$scope"];
