'use strict';
/**
 * @ngInject
 */
function SpinbuttonGroupDirective(){
  // Runs during compile
  return {
    controller: 'SpinbuttonGroupController',
    link: function($scope, iElm, iAttrs, controller) {
      controller.init($scope);

    }
  };
}

module.exports = SpinbuttonGroupDirective;